import React, { useState } from "react";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleChange = (event) => {
    setQuery(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Search here"
        style={{
          height: "3.5vh",
          width: "24vw",
          marginTop: "3vh",
          paddingLeft: "1.5vw",
          marginLeft: "4vw",
          borderRadius: "5px",
        }}
        value={query}
        onChange={handleChange}
      />
    </>
  );
};

export default SearchBar;
