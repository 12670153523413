import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import upload from "./large.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSWR from "swr";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";

import IPUrl from "../IP/url";
import IPUrl7000 from "../IP/url2";

import { Grid } from "@mui/material";
import { MdDelete } from "react-icons/md";
// import { MdDelete } from "react-icons/md";

const Advertisement = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [adminId, setId] = useState("");
  const [addType, setAddType] = useState("T")

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [url, setUrl] = useState("");
  const [advertisementImage, setImage] = useState("");
  const [mainImageUrl, setMainImageUrl] = useState();
  const [FeatureImage, setFeatureImage] = useState();

  const imageRef = useRef();

  const handleClick = () => {
    return imageRef.current;
  };
  

  const submitForm = async () => {
    const myForm = new FormData();
    myForm.append("url", url);
    console.log(advertisementImage);
    myForm.append("advertisementImage", advertisementImage);

    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/addAdvertisement/${adminId}`,

        myForm,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Ad Added");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleImageChange = (e,name) => {
    setImage(e.target.files[0]);
    console.log(advertisementImage, "line 33");
    console.warn(e.target.files[0]);
  };
  console.log(advertisementImage);
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const spanref = useRef(null);

  // useEffect(() => {
  //   const element = inputRef.current;

  //   element.addEventListener("change", () => {
  //     const inputImage = element.files[0];
  //     spanref.current.innerHTML = inputImage.name;
  //   });
  // });

  // handle file change
  const handleFileChange = async (event, name) => {
    const selectedFile = event.target.files;
    console.log(event.target.files,name, "sleected file array");
    // // setFile(selectedFile);
    UploadFile(selectedFile[0], name);

    // if (selectedFile) {
    //   Array.from(selectedFile)?.map((data, index) => {
    //     // console.log(selectedFile.length - 1, index,data);
    //     UploadFile(data, name);
    //   });
    // }
  };

  const UploadFile = async (selectedFile, name) => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await axios.post(`${IPUrl7000}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        if (name === "main") {
          setMainImageUrl(response.data.url);
        } else {
          setFeatureImage(response.data.url);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createAdvertisement = async () => {
    console.log(mainImageUrl,FeatureImage,addType);
    if (mainImageUrl && FeatureImage) {
      let data = {
        adType:addType,
        image:FeatureImage,
        asset:mainImageUrl
      };
      console.log(data,adminId)
      await axios
        .post(`${IPUrl7000}/admin/addAdvertisement/${adminId}`, data)
        .then((res2) => {
          if (res2.status === 200) {
            console.log(res2);
            toast(res2.data.message);
          }
        })
        .catch((err2) => {
          console.log(err2, "Error in uplaod to company stock");
        });
    } else {
      toast.error("Select one feautred image and main image to create advertisement!");
    }
  };
  // fetch ads-:

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/getAdvertisement`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  //  delete ads-:

  async function deleteAd(id) {
    try {
      await axios.get(`${IPUrl}/admin/delete/advertisement/${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div>
          <Navbar />
          <div
            style={{
              height: "97vh",
              width: "80vw",
              overflow: "scroll",
              background: "#EFEFEF",
              paddingTop: "3vh",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "90%", padding: "0px 20px 0px 20px" }}>
              <Grid container spacing={2}>
                <Grid item lg={3} xs={4}>
                  <select value={addType} style={{ width: "100%", height: "4vh" }} onChange={(e)=>
                  {
                    console.log(e.target.value)
                    setAddType(e.target.value)
                  }}>
                    <option value="T">Top</option>
                    <option value="B">Bottom</option>
                  </select>
                </Grid>
                <Grid item lg={3} xs={4}>
                  <div
                    style={{
                      background: "#7400B8",
                      padding: 5,
                      borderRadius: 10,
                    }}
                  >
                    <label
                      // style={{ height: "10vh", width: "10vw" }}
                      htmlFor="advertisement"
                    >
                      <span style={{ color: "#fff", padding: 10, margin: 10 }}>
                        Upload Feature Image
                      </span>
                    </label>
                    {/* <span
                      style={{ fontSize: "12px", marginTop: "5vh" }}
                      ref={spanref}
                    ></span> */}

                    <input
                      // ref={inputRef}
                      style={{ display: "none" }}
                      id="advertisement"
                      // onChange={handleImageChange}
                      onChange={(e)=>handleFileChange(e,"feature")}

                      type="file"
                      accept="image"
                    />
                  </div>
                </Grid>
                <Grid item lg={3} xs={4}>
                  <div
                    style={{
                      background: "#7400B8",
                      padding: 5,
                      borderRadius: 10,
                    }}
                  >
                    <label
                      style={{ height: "5vh", width: "10vw" }}
                      htmlFor="advertisement2"
                    >
                      <span style={{ color: "#fff", padding: 10, margin: 10 }}>
                        Upload Main Image
                      </span>
                    </label>
                    {/* <span
                      style={{ fontSize: "12px", marginTop: "5vh" }}
                      ref={spanref}
                    ></span> */}

                    <input
                      // ref={inputRef}
                      style={{ display: "none" }}
                      id="advertisement2"
                      onChange={(e)=>handleFileChange(e,"main")}
                      type="file"
                      accept="image"
                    />
                  </div>
                </Grid>
                <Grid item lg={3} xs={4}>
                  <button
                    // type="submit"
                    type="button"
                    onClick={()=>createAdvertisement()}
                    style={{
                      height: "4.72vh",
                      // width: "6vw",
                      width: "100%",
                      background: "#7400B8",
                      color: "#FFFFFF",
                      border: "none",
                      borderRadius: "9px",
                    }}
                  >
                    Add
                  </button>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8vh",
                width: "100%",
              }}
            >
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
            <hr style={{ marginTop: "3vh", border: "1px solid #B6B6B6" }} />
            <div style={{ width: "100%" }}>
              {data?.map((ad, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginLeft: "2vw",
                      marginTop: "2vh",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ad.image}
                      style={{
                        width: "10vw",
                        height: "10vh",
                        objectFit: "contain",
                      }}
                    />
                    <img
                      src={ad.asset}
                      style={{
                        width: "10vw",
                        height: "10vh",
                        objectFit: "contain",
                      }}
                    />
                    <span>{ad.adType == "T" ? "TOP" : "BOTTOM"}</span>
                    {/* <input
                    type="text"
                    // placeholder="    https://waltnot.net"
                    value={ad.url}
                    style={{
                      height: "4vh",
                      width: "36vw",
                      marginLeft: "4vw",
                      alignSelf: "center",
                    }}
                  /> */}
                    <MdDelete
                      onClick={() => deleteAd(ad._id)}
                      style={{
                        color: "crimson",
                        marginLeft: "4vmax",
                        fontSize: "1.2rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Advertisement;
