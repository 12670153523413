import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import useSWR from "swr";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { admin, baseUrl } from "../localdata";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import IPUrl from "../IP/url";
import { Box, Switch, Typography } from "@mui/material";
import IPUrl7000 from "../IP/url2";

const Mrates = () => {
  const [adminId, setId] = useState("");
  const open2 = true;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/getB2BStock/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  // store statenames in a unique value array->
  const statesFromResponse = data?.map((d) => d.stateName);
  const uniqueStates = [...new Set(statesFromResponse)];
  const type = "B2B";
  console.log(data, "all plat rates list data");
  const [stockName, setStockName] = useState([]);
  const [loadingName, setLoadingName] = useState(false);
  const [state, setState] = useState("");
  const [selectedStock, setSelectedStock] = useState("");

  const [isToggled, setIsToggled] = useState(false);
  const [AllStockDetail, setAllStockDetail] = useState([]);

  const filteredStocks = data?.filter((value) => {
    return (
      value?.stockName?.trim().toLowerCase() ===
      selectedStock?.trim().toLocaleLowerCase()
    );
  });
  console.log(filteredStocks, "returned filtered stock");

  // get stockName when state is selected->
  const handleSelectState = async (e) => {
    setState(e.target.value);
    const stateName = e.target.value;
    setLoadingName(true);
    // fetch data on select state
    const { data } = await axios.get(
      `${IPUrl}/admin/stateList/${stateName}/${type}/${adminId}`
    );
    setLoadingName(false);
    setStockName(data);
  };

  const navigate = useNavigate();
  const [edit, setedit] = useState(false);
  const [index, setIndex] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [isOpen1, setisOpen1] = useState(false);
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const toAdd = () => {
    navigate("/stockfeato", { state: { type: "B2B" } });
  };

  // delete stock function->

  const handleDelete = async (id) => {
    const stockId = id;
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/deleteStock/${stockId}/${adminId}`
      );
      toast("Stock Deleted");
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // Handle toggle change
  const handleStatusChange = async () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    const stockId = filteredStocks?.[0]?._id;
    console.log(stockId, isToggled, newToggleState);

    try {
      const response = await axios.put(`${IPUrl7000}/stock/update`, {
        isSales: newToggleState === true ? "O" : "C",
        id: stockId,
      });
      // setFilteredStockByname(response.data.allStockStatements?.[0]);
      alert(response.data.message);
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  // create keys for tabel heading->

  const keys = filteredStocks?.[0]?.sizes?.map((item) => Object.keys(item));

  // convert array of objects into single object which has same key value pair->

  const sizeData = filteredStocks?.[0]?.sizes?.reduce((acc, obj) => {
    acc[obj.size] = obj.amount;
    return acc;
  }, {});



  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        {!data || loadingName ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <button
              onClick={toAdd}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "6vw",
                border: "none",
                background: "#7400B8",
                color: "#FFFFFF",
                marginLeft: "60vw",
                marginTop: "4vh",
              }}
            >
              Upload
            </button>
            {/* <AiOutlineArrowLeft
            onClick={prev}
            style={{ cursor: "pointer", marginLeft: "5vw" }}
          />{" "}
          <AiOutlineArrowRight
            onClick={next}
            style={{ cursor: "pointer", marginLeft: "2vw" }}
          /> */}
            <div
              style={{
                display: "flex",
                height: "6vh",
                width: "80vw",
                background: "#7400B8",
                marginTop: "4vh",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "2vw",
                  gap: "2vw",
                  width: "80%",
                }}
              >
                <select
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "none",
                    outline: "none",
                  }}
                  value={state}
                  onChange={handleSelectState}
                >
                  <option style={{ color: "black" }}>Select State</option>
                  {uniqueStates?.map((value, index) => {
                    return (
                      <option key={index} style={{ color: "black" }}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <select
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "none",
                    outline: "none",
                  }}
                  value={selectedStock}
                  onChange={(e) => {
                    setSelectedStock(e.target.value);
                    let filteredStocks = data?.filter((value) => {
                      return (
                        value?.stockName?.trim().toLowerCase() ===
                        e.target.value?.trim().toLocaleLowerCase()
                      );
                    });
                    console.log(
                      filteredStocks?.[0]?.isSales,
                      filteredStocks?.[0]?.isSales === "O" ? true : false,
                      "true fals etru fale"
                    );
                    setIsToggled(
                      filteredStocks?.[0]?.isSales === "O" ? true : false
                    );
                  }}
                >
                  <option style={{ color: "black" }}>Stock name</option>
                  {stockName?.map((value, index) => {
                    return (
                      <option key={index} style={{ color: "black" }}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <div>
                  {selectedStock && (
                    <Box
                      display="flex"
                      alignItems="center"
                    // sx={{ width: "100%" }}
                    >
                      <Typography color={"white"}>
                        Sale {isToggled ? "On" : "Off"}
                      </Typography>
                      <Switch
                        sx={{
                          "& .MuiSwitch-track": {
                            backgroundColor: isToggled ? "white" : "#ccc",
                            transition: "background-color 0.3s",
                            borderRadius: 20,
                          },
                          "& .MuiSwitch-thumb": {
                            width: 24,
                            height: 24,
                            backgroundColor: isToggled ? "white" : "white",
                            transition: "transform 0.3s ease",
                            transform: isToggled
                              ? "translateX(2px)"
                              : "translateX(0px)",
                          },
                          "& .MuiSwitch-switchBase": {
                            padding: 1,
                            transition: "transform 0.3s",
                            "&.Mui-checked + .MuiSwitch-track": {
                              backgroundColor: "white",
                            },
                          },
                        }}
                        checked={isToggled ? true : false}
                        onChange={handleStatusChange}
                        color="primary"
                      />
                    </Box>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    // width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginLeft: "6vw",
                    gap: "2vw",
                  }}
                >
                  {filteredStocks?.length > 0 && (
                    <>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/update/stock/${filteredStocks?.[0]?._id}`}
                      >
                        <MdModeEditOutline fill="white" />
                      </Link>

                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: "6vw",
                  // gap: "2vw",
                }}
              >


                <MdDelete
                  fill="red"
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(filteredStocks?.[0]?._id)}
                />
              </div>

            </div>

            <div
              className="container"
              style={{
                height: "70vh",
                width: "78vw",
                marginLeft: "1vw",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  marginLeft: "0vw",
                  width: "15vw",
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                {isOpen && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      margin: "26vh 0vw 0 -4vw",
                      borderRadius: "10px",
                      position: "absolute",
                      width: "8vw",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                      marginTop: "18vh",
                      marginLeft: "60vw",
                      zIndex: "1",
                      border: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>
                        Round Pipe
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>
                        Square Pipe
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>
                        Rectangle Pipe
                      </h5>
                    </div>
                  </div>
                )}
                {isOpen1 && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      margin: "4vh 0vw 0 6vw",
                      borderRadius: "10px",
                      position: "absolute",
                      width: "8vw",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                      marginTop: "32vh",
                      marginLeft: "70vw",
                      zIndex: "1",
                      border: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>25 OD</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>32 OD</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>42 OD</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>48 OD</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>60 OD</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5 style={{ margin: " 1.5vh 1vw 1.5vh 1vw" }}>76 OD</h5>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ width: "100%", paddingLeft: "10%" }}>
                <Typography align="left" fontWeight={600}>
                  Stock Date{" "}
                  {filteredStocks?.length > 0 &&
                    moment(filteredStocks?.[0]?.stockDate.substr(0, 10)).format(
                      "DD-MM-YYYY"
                    )}
                </Typography>
              </div>

              <div style={{ width: "100%", display: "flex", justifyContent: "left" }}>
                {" "}
                <table className="tab" style={{ width: "40%" }}>
                  <tr className="tabtr">
                    {/* <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Sr No.
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>Date</th>

                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Price
                  </th> */}
                    <th style={{ textAlign: "start", color: "#7400B8" }}>
                      Size
                    </th>
                    <th style={{ textAlign: "start", color: "#7400B8" }}>
                      Amount
                    </th>
                    {/* {keys?.map(([key, value]) => {
                    return (
                      <>
                        <th style={{ textAlign: "start", color: "#7400B8" }}>
                          {key}
                        </th>
                        <th style={{ textAlign: "start", color: "#7400B8" }}>
                          {value}
                        </th>
                      </>
                    );
                  })} */}
                  </tr>

                  {filteredStocks?.length > 0 &&
                    filteredStocks?.[0]?.sizes?.map((item, i) => (
                      <tr key={i} style={{ paddingTop: "2vh" }}>
                        {/* <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        contenteditable={edit ? "true" : "false"}
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {moment(item?.stockDate.substr(0, 10)).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td
                        contenteditable={edit ? "true" : "false"}
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.basic}
                      </td> */}
                        {/* <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {key}
                      </td>
                      <td>{value}</td> */}
                        <td
                          style={{
                            textAlign: "start",
                          }}
                        >
                          {item.size}
                        </td>
                        <td>{item.amount}</td>

                        {/* {Object.entries(sizeData).map(([key, value]) => (
                        <>
                          <td
                            style={{
                              textAlign: "start",
                            }}
                          >
                            {key}
                          </td>
                          <td>{value}</td>
                        </>
                      ))} */}
                        {/* <td
                      contenteditable={edit ? "true" : "false"}
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.stockData?.thickness}
                    </td> */}

                        {/* <td
                        style={{
                          "font-size": "15px",
                          textAlign: "center",
                        }}
                      >
                        <a
                          onClick={() => handleMenuIconClick(item._id)}
                          style={{ cursor: "pointer" }}
                        >
                          <BiDotsVerticalRounded />
                        </a>
                      </td> */}
                        <div
                          style={{
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            display: index === item._id ? "block" : "none",
                            position: "absolute",
                            right: "7.5vw",
                          }}
                        >
                          <>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/update/stock/${item._id}`}
                            >
                              {" "}
                              <div
                                onClick={() => setedit(true)}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  padding: "4px",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <MdModeEditOutline
                                  style={{ paddingRight: "2vw" }}
                                />
                                <h5 style={{ margin: "0" }}>Edit</h5>
                              </div>
                            </Link>
                            <div
                              onClick={() => handleDelete(item._id)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                padding: "4px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <MdDelete style={{ paddingRight: "2vw" }} />
                              <h5 style={{ margin: "0" }}>Delete</h5>{" "}
                            </div>
                          </>
                        </div>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Mrates;
