import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import blueDown from "./blueDown.png";
import { admin, baseUrl } from "../localdata";
import IPUrl from "../IP/url";

const Bills = ({ searchQuery, onSearch }) => {
  const [adminId, setId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/showAllBills/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );


  const filtered = data?.filter((payment) => {
    return payment.userName
      ?.toString()
      ?.trim()
      .toLowerCase()
      .includes(searchQuery);
  });

  const toAdd = () => {
    navigate("/upload/bill")
  }


  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar onSearch={onSearch} />
        <div
          className="tas"
          style={{ background: "#EFEFEF", height: "100vh", width: "initial" }}
        >
          <div style={{ flex: 1, justifyContent: "flex-end", alignItems: "flex-end", display: "flex", }}>
            <button
              onClick={toAdd}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "6vw",
                borderRadius: "6px",
                background: "#7400B8",
                border: "none",
                color: "#FFFFFF",
                margin: 20
              }}
            >
              Upload
            </button>

          </div>
          <div className="container">

            {filtered?.map((details, i) => {
              return (
                <div key={i} style={{ margin: 10 }}>


                  <div
                    style={{
                      borderRadius: "10px",
                      background: "#FFFFFF",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="col-12"
                  >



                    <div style={{ marginLeft: "4vw" }} className="col-6">
                      <h3>
                        Customer Name :
                        {" " + details.userName}
                      </h3>
                      <h3 style={{ color: "gray" }}>
                        Order Number:
                        {" " + details.orderNumber}
                      </h3>
                    </div>



                    <div className="col-6">
                      <h3
                        style={{
                          marginRight: "2vw",
                          alignSelf: "center",
                        }}
                      >
                        {"Order Amount: " + details.amount}
                      </h3>

                    </div>

                  </div>



                </div>
              );
            })}

          </div>

        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Bills;
