import { useState, useEffect } from "react";
import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import { admin, baseUrl } from "../localdata";
import useSWR from "swr";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineWallet } from "react-icons/ai";
import { FaProductHunt } from "react-icons/fa";
import IPUrl from "../IP/url";

const Board = ({ orders }) => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const navigate = useNavigate();
  const toRusers = () => {
    navigate("/rusers");
  };
  const toEnquiry = () => {
    navigate("/enquiry");
  };
  const toTorder = () => {
    navigate("/totalorder");
  };
  const toProducts = () => {
    navigate("/totalproducts");
  };
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/dashboardUserData/${adminId}/?offset=10`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  const [stock, setStock] = useState([]);
  const [newData, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const fetchData = async () => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const adminId = admin._id;
    if (adminId) {
      const { data } = await axios.get(
        `${IPUrl}/admin/dashboardEnquiryData/${adminId}/?offset=10`
      );
      setData(data?.enquiryList);
    }
  };

  const fetchData2 = async () => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const adminId = admin._id;
    if (adminId) {
      const { data } = await axios.get(
        `${IPUrl}/admin/getAllOrders/${adminId}`
      );
      setOrderData(data);
    }
  };

  const fetchAllStocks = async () => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const adminId = admin._id;
    if (adminId) {
      const { data } = await axios.get(
        `${IPUrl}/admin/getAllStock/${adminId}`
      );
      setStock(data);
    }
  };
  console.log(stock, "log stock data");
  useEffect(() => {
    fetchAllStocks();
  }, []);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);

  return (
    <div style={{ display: "flex", marginTop: "4vh" }}>
      <div
        onClick={toRusers}
        style={{
          display: "flex",
          cursor: "pointer",
          justifyContent: "space-around",
          height: "12vh",
          borderRadius: "10px",
          background: "#FFFFFF",
          width: "12vw",
          paddingBottom: "10px",
        }}
      >
        <div style={{ marginTop: "3vh", marginLeft: "1vw" }}>
          <img src={one} />
        </div>
        <div style={{ paddingTop: "1.5vh" }}>
          <h3>{data?.totalUsers}</h3>
          <p style={{ fontSize: "12px", marginTop: "-2vh" }}>Registered User</p>
        </div>
      </div>
      <div
        onClick={toTorder}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-around",
          height: "12vh",
          marginLeft: "4vw",
          borderRadius: "10px",
          background: "#FFFFFF",
          width: "12vw",
          paddingBottom: "10px",
        }}
      >
        <div style={{ marginTop: "3vh", marginLeft: "1vw" }}>
          <img src={two} />
        </div>
        <div style={{ paddingTop: "1.5vh" }}>
          <h3>{orderData?.length}</h3>
          <p style={{ fontSize: "12px", marginTop: "-2vh" }}>Total Order</p>
        </div>
      </div>
      <div
        onClick={toEnquiry}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-around",
          height: "12vh",
          marginLeft: "4vw",
          borderRadius: "10px",
          background: "#FFFFFF",
          width: "12vw",
          paddingBottom: "10px",
        }}
      >
        <div style={{ marginTop: "3vh", marginLeft: "1vw" }}>
          <img src={three} />
        </div>
        <div style={{ paddingTop: "1.5vh" }}>
          <h3>{newData?.length}</h3>
          <p style={{ fontSize: "12px", marginTop: "-2vh" }}>Total Enquiry</p>
        </div>
      </div>
      <div
        onClick={toProducts}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-around",
          height: "12vh",
          marginLeft: "4vw",
          borderRadius: "10px",
          background: "#FFFFFF",
          width: "12vw",
          paddingBottom: "10px",
        }}
      >
        <div style={{ marginTop: "3vh", marginLeft: "1vw" }}>
          <FaProductHunt style={{ fontSize: 'xx-large' }} />
        </div>
        <div style={{ paddingTop: "1.5vh" }}>
          <h3>{stock.length}</h3>
          <p style={{ fontSize: "12px", marginTop: "-2vh" }}>Total Products</p>
        </div>
      </div>
    </div>
  );
};

export default Board;
