import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { admin } from "../localdata.js";
import bell from "./notify.png";
import pic from "./profilePic.png";
import lens from "./lens.png";
import SearchBar from "./SearchBar.jsx";

const Navbar = ({ onSearch }) => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    const storedImageUrl = sessionStorage.getItem('image');
    if (storedImageUrl) {
      setImage(storedImageUrl);
    }
  }, []);
  // console.log(onSearch, 'in navbar');
  const [fullName, setName] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setName(admin.fullName);
    }
  })
  const navigate = useNavigate();
  const toProfile = () => {
    navigate("/profile");
  };
  const toNotifications = () => {
    navigate("/notifications");
  };

  return (
    <div
      style={{
        display: "flex",
        height: "10vh",
        width: "80vw",
        background: "#FFFFFF",
        position: "sticky",
        border: "none",
        flex: 1
      }}
    >
      {/* <img
        src={lens}
        style={{ position: "absolute", marginLeft: "4.2vw", marginTop: "4vh" }}
      /> */}
      {/* search bar component */}
      <SearchBar onSearch={onSearch} />
      <div
        style={{
          height: "7vh",
          display: "flex",
          width: "20vw",
          overflow: "visible",
          justifyContent: "flex-end",
          marginRight: "4vw",
          marginTop: "2vh",
          flex: 1
        }}
      >
        <div
          onClick={toNotifications}
          style={{ cursor: "pointer", alignSelf: "center", margin: 20 }}
        >
          <img src={bell} />
        </div>
        {/* <div
          onClick={toProfile}
          style={{
            width: "4vw",
            cursor: "pointer",
            height: "7vh",
            alignSelf: "center",
            borderRadius: "100%",
          }}
        >
          <img src={image} style={{ height: "100%", width: "100%" }} />
        </div> */}
        <h3 style={{ margin: "0", alignSelf: "center", cursor: "pointer" }} onClick={toProfile}>{fullName}</h3>
      </div>
    </div>
  );
};
export default Navbar;
