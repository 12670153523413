import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import { admin, baseUrl, states } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import { useParams } from "react-router-dom";
import IPUrl from "../IP/url";

const UpdateUser = () => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [formData, setData] = useState(null);

  const open2 = true;
  const { id } = useParams();
  const userId = id;

  const [loading, setLoading] = useState(false);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [fullName, setFullName] = useState("");
  console.log(formData, "form data");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data } = await axios.get(`${IPUrl}/admin/getSingleUser/${userId}`);
      setLoading(false);
      setFullName(data?.fullName);
      setEmail(data?.email);
      setNumber(data?.number);
      setCity(data?.city);
      setZipCode(data?.zipCode);
      setState(data?.state);
      setBusinessType(data?.businessType);
      console.log(data, "fetched data");
    };
    fetchData();
  }, [userId]);
  console.log(subscribed);
  const submitForm = async (e) => {
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/editUser/${adminId}`,
        {
          fullName,
          number,
          email,
          password,
          zipCode,
          city,
          state,
          businessType,
          userId,
          subscribed,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("User Updated");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />

        <form onSubmit={handleSubmit(submitForm)}>
          {loading ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{
                height: "97vh",
                width: "80vw",
                paddingLeft: "2vw",
                background: "#EFEFEF",
              }}
            >
              <h2 style={{ paddingTop: "4vh" }}>Basic Information</h2>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Full Name </h3>
                  <input
                    name="fullName"
                    required
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text"
                    placeholder="    Enter your full name"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Phone Number</h3>
                  <input
                    name="number"
                    type="text"
                    required
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="    Enter your phone number"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "4vh" }}>
                <div>
                  <h3>Email id</h3>
                  <input
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="    Enter your email-id"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                {/* <div>
                  <h3 style={{ marginLeft: "2vw" }}>Password</h3>
                  <input
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    placeholder="    Enter Password"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div> */}
              </div>
              <div style={{ display: "flex", marginTop: "4vh" }}>
                <div>
                  <h3>Zip Code</h3>
                  <input
                    name="zipCode"
                    required
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    type="text"
                    placeholder="   Enter zip code"
                    style={{ height: "4.6vh", width: "19vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>City</h3>
                  <input
                    name="city"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    type="text"
                    placeholder="    City"
                    style={{
                      height: "4.6vh",
                      width: "19vw",
                      marginLeft: "2vw",
                    }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>State</h3>
                  <select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                    style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    <option selected value=""></option>
                    {states.map((state) => {
                      return (
                        <>
                          <option>{state}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "4vh" }}>
                {/* <div>
                  <h3>Language</h3>
                  <input
                    type="text"
                    placeholder="    Enter language"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div> */}
                <div>
                  <h3 style={{}}>Business type</h3>
                  <input
                    required
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                    type="text"
                    placeholder="    Business type"
                    style={{
                      height: "4.6vh",
                      width: "30vw",
                    }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Subscription</h3>
                  <select
                    value={subscribed}
                    onChange={(e) => setSubscribed(e.target.value)}
                    required
                    style={{ height: "5vh", width: "10vw", marginLeft: "2vw" }}
                  >
                    <option selected value=""></option>
                    <option value={true}>Subscribed</option>
                    <option value={false}>Not Subscribed</option>
                  </select>
                </div>
              </div>

              <button
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "4vh",
                  marginLeft: "54vw",
                  border: "none",
                  borderRadius: "9px",
                  cursor: "pointer",
                }}
                type="submit"
                disabled={isSubmitting}
              >
                ADD
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default UpdateUser;
