import React, { useEffect, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Forgot from "./components/Forgot/Forgot";
import Otp from "./components/Otp/Otp";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Home from "./components/Home/Home";
import UserManagement from "./components/UserManagement/UserManagement";
import AddUser from "./components/UserManagement/AddUser";
import Prates from "./components/Mrates/Prates";
import Drates from "./components/Mrates/Drates";
import Add from "./components/Mrates/Add";
import Bills from "./components/Bills/Bills";
import Stocks from "./components/Stocks/Stocks";
import Payments from "./components/Payments/Payments";
import AddPay from "./components/AddPay/AddPay";
import RegisteredUsers from "./components/RegisteredUsers/RegisteredUsers";
import Enquiry from "./components/Enquiry/Enquiry";
import TotalOrder from "./components/TotalOrder/TotalOrder";
import Orders from "./components/Orders/Orders";
import StockFeat from "./components/StockFeat/StockFeat";
import Graph from "./components/Graph/Graph";
import Advertisement from "./components/Advertisement/Advertisement";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import Notifications from "./components/Notifications/Notifications";
import StockFeatO from "./components/StockFeatO/StockFeatO";
import UpdateStock from "./components/StockFeatO/UpdateSock";
import StockUpload from "./components/Formupload/StockUpload";
import UpdatePay from "./components/AddPay/UpdatePay";
import UpdateUser from "./components/RegisteredUsers/UpdateUser";
import Navbar from "./components/Navbar/Navbar";
import B2bUpload from "./components/StockFeatO/B2bUpload";
import EnquiryDetail from "./components/Enquiry/EnquiryDetial";
import UploadBill from "./components/Bills/UploadBill";
import TotalProducts from "./components/TotalOrder/TotalProducts";
import { useLocation } from "react-router-dom";
import About from "./components/About/About";
import Contact from "./components/Contactus/Contact";
import { NewOrder } from "./components/Orders/newOrders";
const LazyHome = React.lazy(() => import("./components/Home/Home"));

const RoutesList = () => {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const location = useLocation();

  // clear the serchquery when location changes->
  useEffect(() => {
    setSearchQuery("");
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={admin ? <Home /> : <Login />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/forgot" element={<Forgot />} />
      <Route exact path="/otp" element={<Otp />} />
      <Route exact path="/change" element={<ChangePassword />} />
      <Route
        exact
        path="/home"
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <LazyHome searchQuery={searchQuery} onSearch={handleSearch} />
          </Suspense>
        }
      />
      <Route
        exact
        path="/user"
        element={
          <UserManagement searchQuery={searchQuery} onSearch={handleSearch} />
        }
      />
      <Route exact path="/adduser" element={<AddUser />} />
      <Route exact path="/plantrates" element={<Prates />} />
      <Route exact path="/add" element={<Add />} />
      <Route exact path="/bills" element={<Bills searchQuery={searchQuery} onSearch={handleSearch} />} />
      <Route exact path="/stocks" element={<Stocks />} />
      <Route
        exact
        path="/payments"
        element={<Payments searchQuery={searchQuery} onSearch={handleSearch} />}
      />
      <Route exact path="/addpay" element={<AddPay />} />
      <Route exact path="/rusers" element={<RegisteredUsers />} />
      <Route
        exact
        path="/enquiry"
        element={<Enquiry searchQuery={searchQuery} onSearch={handleSearch} />}
      />
      <Route exact path="/totalorder" element={<TotalOrder />} />
      <Route
        exact
        path="/orders"
        element={<Orders searchQuery={searchQuery} onSearch={handleSearch} />}
      />
      <Route exact path="/newOrder" element={<NewOrder searchQuery={searchQuery} onSearch={handleSearch} />} />
      <Route exact path="/graph" element={<Graph />} />
      <Route exact path="/ad" element={<Advertisement />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/editprofile" element={<EditProfile />} />
      <Route exact path="/notifications" element={<Notifications />} />
      <Route exact path="/depotrates" element={<Drates />} />
      <Route exact path="/stockfeato" element={<StockFeatO />} />
      <Route exact path="/update/stock/:id" element={<UpdateStock />} />
      <Route exact path="/upload/stock" element={<StockUpload />} />
      <Route path="/update/pay/:id" element={<UpdatePay />} />
      <Route path="/update/user/:id" element={<UpdateUser />} />
      <Route path="/b2b/upload" element={<B2bUpload />} />
      <Route path="/enquiry/detail/:id" element={<EnquiryDetail />} />
      <Route path="/upload/bill" element={<UploadBill />} />
      <Route
        path="/totalproducts"
        element={
          <TotalProducts searchQuery={searchQuery} onSearch={handleSearch} />
        }
      />
      <Route path="/mynavbar" element={<Navbar onSearch={handleSearch} />} />
      {/* <Route path="/about" element={<About/>} /> */}
    </Routes>
  );
};

export default RoutesList;
