import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { AiOutlineDown } from "react-icons/ai";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import useSWR from "swr";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./dropdown.css";
import { colourStyles } from "./SelectStyle";
import Select from "react-select";
import axios, { all } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { admin, baseUrl } from "../localdata";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import stockIcon from "./stock.png";
import { Navigate, Link } from "react-router-dom";
import IPUrl from "../IP/url";
import { Modal, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "react-scroll";
import IPUrl7000 from "../IP/url2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  background: "#fff",
};

const Stocks = () => {
  const open2 = true;

  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");
  const [data, setData] = useState([]);
  const fecthData = async () => {
    const { data } = await axios.get(`${IPUrl}/user/get/stockStatement`);
    console.log(data);
    setData(data);
  };
  useEffect(() => {
    fecthData();
  }, []);

  const stockData = data?.stockData;
  console.log(stockData, "line 37");
  // get all stock name when select state->
  const [selectStockName, setSelectStockName] = useState("");
  const navigate = useNavigate();
  const [index, setIndex] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [isOpen1, setisOpen1] = useState(false);
  const [edit, setedit] = useState(false);
  const [stockName, setStockName] = useState("");
  const [showModal, setShowModal] = useState(false);

  // console.log(stockName, "line 48");
  // const splitStockName = stockName.split(",")[0];
  // const splitStateName = stockName.split(",")[1];
  // console.log(splitStockName, splitStateName);

  const [isToggled, setIsToggled] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [state, setState] = useState("");
  const [loadingName, setLoadingName] = useState(false);
  const [AllStockDetail, setAllStockDetail] = useState([]);
  const [filteredStockByname, setFilteredStockByname] = useState([]);
  const [selectedStockType, setSelectedStockType] = useState("");

  const [selectedStock, setSelectedStock] = useState(null);

  function handleMenuIconClick(id) {
    console.log(id, "get id on click");
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const toAdd = () => {
    navigate("/upload/stock");
  };

  const tob2b = () => {
    navigate("/b2b/upload");
  };

  const toStockfeat = () => {
    navigate("/stockfeato");
  };

  //  get filtered stock by name ->

  // delete stock api->

  const handleClick = async (id) => {
    const stockId = id;
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/deleteStock/${stockId}/${adminId}`
      );
      toast("Stock Deleted");
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // impelement pagination featature->

  const [currentPage, setCurrentPage] = useState(1);

  const [stockPerPage] = useState(8);

  const indexOfLastStock = currentPage * stockPerPage;
  const indexOfFirstStock = indexOfLastStock - stockPerPage;

  const handleSelectState = async (e) => {
    if (state != "") {
      setSelectedStockType(e.target.value);
      setLoadingName(true);
      // fetch data on select state and type
      const { data } = await axios.get(
        `${IPUrl7000}/admin/stateList/${state}/${e.target.value}/${adminId}`
      );
      setLoadingName(false);
      setStockName(data);
    } else {
      alert("First Select the state to list the stock!");
    }
  };

  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await axios.get(
          `${IPUrl}/admin/getB2BStock/${adminId}`
        );
        const response2 = await axios.get(
          `${IPUrl}/admin/getB2CStock/${adminId}`
        );
        const stockListData = response.data.concat(response2.data);
        setAllStockDetail(stockListData);
        // Filter to get only unique state items by name
        const uniqueStockData = response.data.filter(
          (item, index, self) =>
            index ===
            self.findIndex((stock) => stock.stateName === item.stateName)
        );

        setStateData(uniqueStockData);
      } catch (error) {
        console.error("Error fetching stock data:", error);
      }
    };

    // Set up the interval
    // const intervalId = setInterval(() => {
    //   fetchStateData();
    // }, 1000);
    fetchStateData();

    // return () => clearInterval(intervalId);
  }, [IPUrl, adminId]);

  const next = () => {
    if (currentPage < Math.ceil(data?.length / stockPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  // handle file change
  const handleFileChange = async (event) => {
    console.log(
      selectStockName,
      event.target.files[0],
      AllStockDetail?.filter(
        (stock) => stock.stockName === selectStockName
      )?.[0],
      "selected stock"
    );
    if (selectStockName) {
      const selectedFile = event.target.files;
      console.log(event.target.files, "sleected file array");
      // // setFile(selectedFile);

      if (selectedFile) {
        Array.from(selectedFile)?.map((data,index) => {
          console.log(selectedFile.length-1,index)
          UploadFile(data, index, selectedFile.length-1);
        });
      }
    } else {
      alert("Select One stock to upload file!");
    }
  };

  const UploadFile = async (selectedFile, index, length) => {
    const stockId = AllStockDetail?.filter(
      (stock) => stock.stockName === selectStockName
    )?.[0]?._id;
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await axios.post(`${IPUrl7000}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        let payload = {
          companyId: stockId,
          fileOriginalName:selectedFile.name,
          fileType: selectedFile.type,
          fileLink: response.data.url,
        };
        console.log(payload);
        createCompanyStock(payload, index, length);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createCompanyStock = async (data, index, length) => {
    await axios
      .post(`${IPUrl7000}/company-stock/create`, data)
      .then((res2) => {
        if (res2.status === 200) {
          console.log(res2);
          GetDetailsofStockById(data.companyId);

          if (index === length) {
            toggleModal();

            alert(res2.data.message);
          }
        }
      })
      .catch((err2) => {
        console.log(err2, "Error in uplaod to company stock");
      });
  };

  // fetch the status of stock
  const fetchStatusDetail = async (val) => {
    const stockId = AllStockDetail?.filter(
      (stock) => stock.stockName === val
    )?.[0]?._id;
    GetDetailsofStockById(stockId);
    await axios
      .get(`${IPUrl7000}/user/get/stockbyID?id=${stockId}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res, AllStockDetail, "res of status");
          setIsToggled(res.data.allStockStatements?.[0]?.isSales);
        }
      })
      .catch((err) => {
        console.log(err, "error fetch status");
      });
    // setIsToggled(response.data.isToggled);
  };

  const GetDetailsofStockById = async (stockId) => {
    if (setSelectStockName !== "") {
      await axios
        .get(`${IPUrl7000}/company-stock/get-by-company/${stockId}`)
        .then((res) => {
          if (res.status === 200) {
            console.log("rres of company stock detail", res);
            setFilteredStockByname(res.data);
          }
        })
        .catch((err) => {
          console.log("error:", err);
        });
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />

        {!loadingName ? (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                paddingTop: "4vh",
                marginRight: "4.2vw",
              }}
            >
              {/* <button
      onClick={toStockfeat}
      style={{
        cursor: "pointer",
        width: "10vw",
        height: "4vh",
        borderRadius: "9px",
        marginRight: "2vw",
        background: "#7400B8",
        border: "none",
        color: "#FFFFFF",
      }}
    >
      Create stock
    </button> */}
              <AiOutlineArrowLeft
                onClick={prev}
                style={{ cursor: "pointer" }}
              />{" "}
              <AiOutlineArrowRight
                onClick={next}
                style={{ marginLeft: "1vw", cursor: "pointer" }}
              />
            </div>

            <button
              onClick={toggleModal}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "6vw",
                border: "none",
                background: "#7400B8",
                color: "#FFFFFF",
                marginLeft: "70vw",
                marginTop: "4vh",
              }}
            >
              Upload
            </button>
            <div
              style={{
                display: "flex",
                height: "6vh",
                width: "80vw",
                background: "#7400B8",
                marginTop: "4vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "6vw",
                }}
              >
                <div>
                  <select
                    style={{
                      background: "transparent",
                      color: "white",
                      border: "none",
                      outline: "none",
                      padding: 10,
                    }}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option style={{ color: "black" }}>
                      Select State Name
                    </option>
                    {stateData?.map((value, index) => {
                      return (
                        <>
                          <option style={{ color: "black" }}>
                            {value.stateName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <select
                    style={{
                      background: "transparent",
                      color: "white",
                      border: "none",
                      outline: "none",
                      padding: 10,
                    }}
                    value={selectedStockType}
                    onChange={(e) => {
                      handleSelectState(e);
                    }}
                  >
                    <option style={{ color: "black" }}>
                      Select Stock Type
                    </option>
                    {["B2B", "B2C"]?.map((value, index) => {
                      return (
                        <>
                          <option style={{ color: "black" }}>{value}</option>
                        </>
                      );
                    })}
                  </select>
                  <select
                    style={{
                      background: "transparent",
                      color: "white",
                      border: "none",
                      outline: "none",
                      padding: 10,
                    }}
                    value={selectStockName}
                    onChange={(e) => {
                      console.log(e.target.value, "selected stock name");
                      setSelectStockName(e.target.value);
                      fetchStatusDetail(e.target.value);
                    }}
                  >
                    <option style={{ color: "black" }}>
                      Select Stock name
                    </option>
                    {stockName &&
                      stockName?.map((value, index) => {
                        return (
                          <>
                            <option style={{ color: "black" }}>{value}</option>
                          </>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="container"
              style={{
                height: "60vh",
                width: "78vw",
                marginLeft: "1vw",
                overflow: "auto",
                zIndex: "0",
              }}
            >
              <div
                style={{
                  marginLeft: "0vw",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "grid",
                }}
              >
                {filteredStockByname &&
                  filteredStockByname.map((v, index) => {
                    return (
                      <ul style={{ display: "grid" }} key={index}>
                        <li>
                          <a href={v.fileLink} _blank>
                            {v.fileLink}
                          </a>
                        </li>
                      </ul>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add new stock file
          </Typography>
          <input type="file" multiple onChange={handleFileChange} />
        </Box>
      </Modal>
    </div>
  );
};
export default Stocks;
