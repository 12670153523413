import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSWR from "swr";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IPUrl from "../IP/url";
import { Button } from "@mui/material";

const AddPay = () => {
  const [loadingName, setLoadingName] = useState(false);
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  // fetch numbers list data->
  // const fetcher = (url) => axios.get(url).then((res) => res.data);
  // const { data, error, loading } = useSWR(`${IPUrl}/admin/all/numbers`, fetcher, {
  //   refreshInterval: 1000,
  // });


  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/userManagement/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  const showingData = data?.map(_i => ({ id: _i._id, fullName: _i.fullName, number: _i.number }))
  console.log({ showingData })



  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentTime, setPaymentTime] = useState("");
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [totalOutstandingPayment, setTotalOutstanding] = useState("");
  const [totalPaidAmount, setTotalPaidAMount] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [currentId, setcurrentId] = useState("")

  // handlechange for numbers->

  async function handleNumberChange(e) {
    const namedata = showingData?.filter((user) => {
      return user?.number?.trim().includes(e.target.value);
    }) ?? "";
    setFullName(namedata[0].fullName)
    setcurrentId(namedata[0].id)
    setNumber(e.target.value);
  }

  async function handleNameChange(e) {
    const numberdata = showingData?.filter((user) => {
      return user?.fullName?.trim().includes(e.target.value);
    }) ?? "";
    setcurrentId(numberdata[0].id)
    setFullName(e.target.value)
    setNumber(numberdata[0].number);
  }

  const submitForm = async () => {
    try {
      const { data } = await axios.post(`${IPUrl}/admin/addPayment/${adminId}`, {
        paymentDate,
        paymentTime,
        fullName,
        number,
        productName,
        netPrice,
        orderQuantity,
        totalOutstandingPayment,
        totalPaidAmount,
        orderNumber,
      });
      toast("Payment Added");
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const searchOutStanding = async () => {

    try {
      const { data } = await axios.get(`${IPUrl}/order-bill/getBillsforUserWithSettlement/${currentId}/${number}`);
      // {"totalBillAmount":200,"totalsettlementAmount":0}
      // console.log(data);
      setTotalOutstanding(data.totalBillAmount - data.totalsettlementAmount)
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  }

  const open2 = true;
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={handleSubmit(submitForm)}>
          {loadingName && !data ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{
                height: "100vh",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "8vh" }}>
                <div>
                  <h3>Date</h3>
                  <input
                    required
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    type="date"
                    placeholder="    Date"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>

                <div style={{ display: "flex" }}>

                  <div style={{ marginLeft: 19 }}>
                    <h3 style={{ marginLeft: "2vw" }}>Full Name</h3>
                    <input list="browserss" name="browsers" id="browsers"
                      required
                      value={fullName}
                      onChange={(e) => handleNameChange(e)}
                      type="text"
                      placeholder="Name"
                      style={{ height: "4vh", width: "30vw" }} />

                    <datalist
                      id="browserss"
                      required
                      style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                    >
                      {showingData?.map(_i => _i.fullName)?.map((value, index) => {
                        return (
                          <>
                            <option key={index} value={value}>{value}</option>
                          </>
                        );
                      })}
                    </datalist>
                  </div>
                </div>

              </div>


              <div style={{ display: "flex" }}>

                <div style={{}}>
                  <h3 style={{}}>Phone</h3>
                  <input list="phone" name="browsers" id="browsers"
                    required
                    value={number}
                    onChange={(e) => handleNumberChange(e)}
                    type="text"
                    placeholder="phone"
                    style={{ height: "4vh", width: "30vw" }} />
                  <datalist
                    id="phone"
                    required
                    style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    {showingData?.map(_i => _i.number)?.map((value, index) => {
                      return (
                        <>
                          <option key={index} value={value}>{value}</option>
                        </>
                      );
                    })}
                  </datalist>

                </div>
              </div>
              {/* <div style={{ display: "flex" }}>

                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Phone Number</h3>
                  <input
                    list="phone"
                    name="phone"
                    id="phone"
                    required
                    value={number || ""}
                    onChange={(e) => handleNumberChange(e)}
                    type="text"
                    placeholder="Phone"
                    style={{ height: "4vh", width: "30vw" }} />

                  <datalist
                    id="phone"
                    required
                    style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    {showingData?.map(_i => _i.number)?.map((value, index) => {
                      return (
                        <>
                          <option key={index} value={value}>{value}</option>
                        </>
                      );
                    })}
                  </datalist>
                </div>
              </div> */}

              <Button onClick={searchOutStanding}>Search</Button>

              <div style={{ display: "flex" }}>
                <div>
                  <h3>Total Outstanding Payment</h3>
                  <input
                    required
                    disabled
                    value={totalOutstandingPayment}
                    onChange={(e) => setTotalOutstanding(e.target.value)}
                    type="number"
                    placeholder="    Total Outstanding Payment"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Total Paid Amount</h3>
                  <input
                    required
                    value={totalPaidAmount}
                    onChange={(e) => setTotalPaidAMount(e.target.value)}
                    type="number"
                    placeholder="    Total Paid Amount"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <button
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "5vh",
                  marginLeft: "54vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                disabled={isSubmitting}
              >
                ADD
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default AddPay;
